import React from 'react';
import {
  ItemStyled,
  TableHeaderStyled,
  BlockStyled,
  ItemRowStyled,
  TimeItemStyled,
  DetailItemStyled,
} from '../OverviewStyled';

export default function FinTech({ ...props }) {
  return <div className={'w-full flex flex-col items-start'} {...props}>
    <TableHeaderStyled>
      <ItemStyled className={'w-[20%]'}>Time</ItemStyled>
      <ItemStyled className={'w-[60%]'}>Details</ItemStyled>
      <ItemStyled className={'w-[20%]'}>Session</ItemStyled>
    </TableHeaderStyled>
    <BlockStyled>
      <div className={'w-[80%] flex flex-col items-start'}>
        <ItemRowStyled>
          <TimeItemStyled>{`14:00 ~ 14:10\n(“10)`}</TimeItemStyled>
          <DetailItemStyled>Host Welcome Speech: Invest Seoul</DetailItemStyled>
        </ItemRowStyled>
        <ItemRowStyled>
          <TimeItemStyled>{`14:10 ~ 14:20\n(“10)`}</TimeItemStyled>
          <DetailItemStyled>Host Welcome Speech: Seoul Fintech Lab</DetailItemStyled>
        </ItemRowStyled>
        <ItemRowStyled>
          <TimeItemStyled>{`14:20 ~ 15:00\n(“40)`}</TimeItemStyled>
          <DetailItemStyled>IR Pitching</DetailItemStyled>
        </ItemRowStyled>
        <ItemRowStyled>
          <TimeItemStyled>{`15:00 ~ 15:20\n(“20)`}</TimeItemStyled>
          <DetailItemStyled>Break Time</DetailItemStyled>
        </ItemRowStyled>
      </div>
      <ItemStyled className={'w-[20%] !text-white'}>Session 1</ItemStyled>
    </BlockStyled>
    <BlockStyled>
      <div className={'w-[80%] flex flex-col items-start'}>
        <ItemRowStyled>
          <TimeItemStyled>{`15:20 ~ 15:40\n(“20)`}</TimeItemStyled>
          <DetailItemStyled>
            <b className={'font-extrabold'}>Seminar 1.</b>
            Fintech Investment Trend in Southeast Asia
          </DetailItemStyled>
        </ItemRowStyled>
        <ItemRowStyled>
          <TimeItemStyled>{`15:40 ~ 16:00\n(“20)`}</TimeItemStyled>
          <DetailItemStyled>
            <b className={'font-extrabold'}>Seminar 2.</b>
            Market Entry Strategies in Singapore
          </DetailItemStyled>
        </ItemRowStyled>
        <ItemRowStyled>
          <TimeItemStyled>{`16:00 ~ 17:00\n(“60)`}</TimeItemStyled>
          <DetailItemStyled>
            Networking Session
          </DetailItemStyled>
        </ItemRowStyled>
        <ItemRowStyled>
          <TimeItemStyled>{`17:00 ~ 17:15\n(“15)`}</TimeItemStyled>
          <DetailItemStyled>
            End of Event
          </DetailItemStyled>
        </ItemRowStyled>
      </div>
      <ItemStyled className={'w-[20%] !text-white'}>Session 2</ItemStyled>
    </BlockStyled>
  </div>;
}
