import React from 'react';
import Map from '../Map';
import { InnerContainerStyled, NavigationStyled, TitleContainerStyled } from './NavigationStyled';
import { TitleText } from '../commonStyled';

export default function Navigation({ language }) {
  return (
    <NavigationStyled>
      <InnerContainerStyled>
        <TitleText className={'!text-white'}>{language === 'en' ? 'LOCATION' : '오시는 길'}</TitleText>
        {/*<Map />*/}
        <TitleContainerStyled>
          <span
            className={`text-white text-body2 whitespace-pre-wrap font-bold`}>주소 : 서울특별시 영등포구 여의도동 국제금융로 10 (여의도동 23)</span>
          <div className={`w-full flex flex-col items-start gap-[6px]`}>
            <span className={`text-gray-300 text-body2 font-bold`}>* 대중교통 이용 시 상세내역 전달드립니다.</span>
            <span className={`text-white text-body2 font-bold`}>지하철 : 5호선 여의도역 / 9호선 여의도역 - 3번 출구</span>
            <ul className={`list-disc pl-[24px]`}>
              <li className={`text-white text-body2 font-bold`}>320m의 지하 무빙워크를 통해 IFC MALL L2층과 직접 연결</li>
            </ul>
            <span className={`text-white text-body2 font-bold`}>버스 : 여의도 환승센터 하차</span>
            <ul className={`list-disc pl-[24px]`}>
              <li className={`text-white text-body2 font-bold`}>간선 : 160, 260, 261, 262, 360, 600, 662, N16</li>
              <li className={`text-white text-body2 font-bold`}>지선 : 5012, 5615, 5618, 5623, 6623, 6628, 6633, 8564</li>
              <li className={`text-white text-body2 font-bold`}>경기 : 10, 11-1, 11-2, 83, 88, 301, 320, 530, 5609, 8600, 8601, G6005, M6751</li>
            </ul>
          </div>
        </TitleContainerStyled>
      </InnerContainerStyled>
    </NavigationStyled>
  );
}
