import React, { useCallback, useState } from 'react';
import { Footer, Invitation, Main, Navigation, Overview, Registration } from '../../components';

export default function Fintech(){
  const [language, setLanguage] = useState('en');

  const handleChangeLanguage = useCallback((lang) => {
    setLanguage(lang);
  }, []);

  return <>
    <Main language={language} handleChangeLanguage={handleChangeLanguage}/>
    <Invitation language={language}/>
    <Overview language={language}/>
    <Registration language={language}/>
    <Navigation language={language}/>
    <Footer />
  </>
}
