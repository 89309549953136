import tw from 'twin.macro';
import styled from '@emotion/styled';

export const FooterStyled = styled('div')(({ field }) => [tw`
  w-full
  py-[24px]
  flex
  items-center
  justify-center
`,(() => {
  switch (field){
    case 'ai':
      return tw`bg-[#0B3740]`
    case 'bio':
      return tw`bg-[#00437c]`
    case 'contents':
      return tw`bg-[#331544]`
    default:
      return tw`bg-[#101936]`
  }
})]);

export const InnerContainerStyled = tw.div`
  w-10/12
  flex
  flex-col
  items-center
  gap-[20px]
  sm:items-start
  md:items-start
`;

export const TextStyled = tw.span`
  text-white
  text-body2
  font-bold
  sm:text-caption1
  md:text-caption1
`;
