import React from 'react';
import { FieldBg, MainStyled } from './MainStyled';

export default function Main({ language, handleChangeLanguage, field, children }) {
  return (<MainStyled>
    {(!field || field === 'fintech') &&
      <FieldBg alt={'main bg'} src={'fin_tech_bg.png'} />
    }
    {field === 'quantum' &&
      <FieldBg alt={'main bg'} src={'quantum_bg.png'} />
    }
    <div
      className={`absolute top-[30px] right-[40px] sm:top-[12px] sm:right-[12px] md:top-[20px] md:right-[20px] flex items-center gap-[8px]`}>
        <span
          className={`text-heading1 sm:text-body1 md:text-heading3 font-bold cursor-pointer ${language === 'en' ? 'text-red-500' : 'text-white'}`}
          onClick={() => handleChangeLanguage('en')}>EN</span>
      <span className={`text-white text-heading1 sm:text-body1 md:text-heading3 font-bold`}>|</span>
      <span
        className={`text-heading1 sm:text-body1 md:text-heading3 font-bold cursor-pointer ${language === 'kr' ? 'text-red-500' : 'text-white'}`}
        onClick={() => handleChangeLanguage('kr')}>KR</span>
    </div>
    {children}
  </MainStyled>);
}
