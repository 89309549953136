import React from 'react';
import { TitleText, InnerContainer } from '../../commonStyled';
import { InvitationStyled, InvitationSummaryStyled, SummaryTextStyled } from '../InvitationStyled';
export default function FinTech({ language, ...props }){
  return <InvitationStyled {...props}>
    <InnerContainer>
      <TitleText field='ai'>INVITATION</TitleText>
      <InvitationSummaryStyled>
        <SummaryTextStyled>
          {language === 'en' ?
            `Warm Greetings from Invest Seoul!\nIt is our great pleasure to invite you to the upcoming “Fintech Investment Trend Seminar & Demoday” scheduled to be held on December 12th, 2024\n\nThis event includes seminars on overall insight for Korean start ups, SEA region’s Fintech market, successful entry into SEA, and How to enter the SEA market.\n\nMoreover, the event provides IR pitching session between Domestic Fintech companies and SEA VCs, and there also will be a separate 1:1 meeting and networking session for the participating companies and VCs.\n\nThis will be a great opportunity for both key stakeholders in the global capital market to better understand Korean companies and Seoul and further expand their business.\n\n`
            :
            `안녕하세요, 핀테크 투자 트렌드 세미나입니다.\n핀테크 관계자 여러분들을 오는 “Fintech Trend & Investment Seminar 2024”에 초청드리고자 합니다.\n\n본 행사는 2024년 12월 12일에 개최되는 세미나로 한국 스타트업에 대한 전반적인 인사이트, SEA 지역의 핀테크 시장, SEA 시장 성공 진출 사례, 그리고 SEA 시장 진출 방법에 관한 세미나로 구성됩니다.\n\n또한, 국내 핀테크 기업과 SEA 지역 VC 간의 IR 피칭 세션이 마련되며, 참여 기업과 VC를 대상으로 별도의 1:1 미팅 및 네트워킹 세션도 진행됩니다.\n\n본 행사에 참석하시어 글로벌 시장으로의 진출을 앞두고 있는 스타트업을 만날 수 있는 기회와 비즈니스를 확장할 수 있는 기회를 가지시길 바랍니다.\n\n`
          }
          <b>{language === 'en' ? 'Please grace this occasion with your presence. We look forward to meeting you!' : `시간내시어 자리 빛내주시면 감사하겠습니다.`
          }</b>
        </SummaryTextStyled>
      </InvitationSummaryStyled>
    </InnerContainer>
  </InvitationStyled>
}
