import React from 'react';
import {
  ItemStyled,
  TableHeaderStyled,
  BlockStyled,
  ItemRowStyled,
  TimeItemStyled,
  DetailItemStyled,
} from '../OverviewStyled';
export default function Quantum({...props}){
  return <div className={'w-full flex flex-col items-start'} {...props}>
    <TableHeaderStyled>
      <ItemStyled className={'w-[20%]'}>Time</ItemStyled>
      <ItemStyled className={'w-[60%]'}>Details</ItemStyled>
      <ItemStyled className={'w-[20%]'}>Session</ItemStyled>
    </TableHeaderStyled>
    <BlockStyled>
      <div className={'w-[80%] flex flex-col items-start'}>
        <ItemRowStyled>
          <TimeItemStyled>{`13:00 ~ 13:10\n(“10)`}</TimeItemStyled>
          <DetailItemStyled>Host Welcome Speech: Invest Seoul</DetailItemStyled>
        </ItemRowStyled>
        <ItemRowStyled>
          <TimeItemStyled>{`13:10 ~ 13:50\n(“40)`}</TimeItemStyled>
          <DetailItemStyled>
            <b className={'font-extrabold'}>Seminar 1.</b>
            Global Quantum Trend and 2025 Forecast
          </DetailItemStyled>
        </ItemRowStyled>
        <ItemRowStyled>
          <TimeItemStyled>{`13:50 ~ 14:20\n(“30)`}</TimeItemStyled>
          <DetailItemStyled>
            <b className={'font-extrabold'}>Seminar 2.</b>
            Quantum Technologies at AWS
          </DetailItemStyled>
        </ItemRowStyled>
        <ItemRowStyled>
          <TimeItemStyled>{`14:20 ~ 15:00\n(“20)`}</TimeItemStyled>
          <DetailItemStyled>IR Pitching</DetailItemStyled>
        </ItemRowStyled>
      </div>
      <ItemStyled className={'w-[20%] !text-white'}>Session 1</ItemStyled>
    </BlockStyled>
    <BlockStyled>
      <div className={'w-[80%] flex flex-col items-start'}>
        <ItemRowStyled>
          <TimeItemStyled>{`15:00 ~ 15:30\n(“30)`}</TimeItemStyled>
          <DetailItemStyled>
            Networking Session
          </DetailItemStyled>
        </ItemRowStyled>
        <ItemRowStyled>
          <TimeItemStyled>{`15:30 ~ 16:10\n(“40)`}</TimeItemStyled>
          <DetailItemStyled>
            1 on 1 Business Meeting (VCs-Startups)
          </DetailItemStyled>
        </ItemRowStyled>
      </div>
      <ItemStyled className={'w-[20%] !text-white'}>Session 2</ItemStyled>
    </BlockStyled>
  </div>;
}
