import React from 'react';
import { LogoImageStyled, SponsorTextStyled } from '../Main/MainStyled';
import { FooterStyled, InnerContainerStyled, TextStyled } from './FooterStyled';

export default function Footer({ field, ...props }) {

  return <FooterStyled {...props}>
    <InnerContainerStyled>
      <div className={`flex flex-col items-center gap-[24px] sm:items-start md:items-start`}>
        <div className={`flex items-center gap-[12px]`}>
          <SponsorTextStyled>Co-Host</SponsorTextStyled>
          <LogoImageStyled src={'invest_seoul_white_logo.png'} />
          {(!field || field === 'fintech') && <img alt='fintech lab logo' src={'finttech_white_logo.png'} className={'w-[100px]'} />}
          {field === 'quantum' && <img alt='fintech lab logo' src={'uos_white_logo.png'} className={'w-[100px]'} />}
        </div>
      </div>
      <div className={`mt-[8px] flex flex-col items-center sm:items-start md:items-start`}>
        <TextStyled>투자트렌드세미나 운영사무국</TextStyled>
        <TextStyled>E. contact@investtrend2024.com</TextStyled>
      </div>
    </InnerContainerStyled>
  </FooterStyled>;
}
