import tw from 'twin.macro';

export const NavigationStyled = tw.div`
  w-full
  py-[48px]
  flex
  justify-center
  gap-[24px]
  bg-[#101936]
`;

export const InnerContainerStyled = tw.div`
  w-10/12
  flex
  flex-col
  items-start
  gap-[20px]
`;

export const TitleContainerStyled = tw.div`
  w-full
  flex
  flex-col
  items-start
  gap-[12px]
`;
