import tw from 'twin.macro';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const RegistrationStyled = tw.article`
  w-full
  py-[60px]
  flex
  flex-col
  items-center
  justify-center
  bg-white
`;

export const RegistrationBoxStyled = tw.div`
  w-full
  pb-[20px]
  flex
  flex-col
  items-start
  gap-[16px]
  sm:gap-[8px]
  md:gap-[8px]
`;

export const InputTitleStyled = tw.span`
  text-heading4
  sm:text-body2
  md:text-body2
  font-bold
  leading-[24px]
  whitespace-pre-wrap
`;

export const InformationContainerStyled = tw.div`
  w-full
  flex
  items-center
  gap-[8px]
  justify-between
  sm:flex-col
  sm:items-start
  sm:justify-start
  md:flex-col
  md:items-start
  md:justify-start
`;

export const RegistrationTitleTextStyled = tw.span`
  min-w-[20%]
  text-body2
  font-bold
  sm:text-caption1
  md:text-caption1
`;

export const GridContainer = styled.div(({ gap = 8 }) => [css`
  gap: ${gap}px
`, tw`
    w-full
    grid
    grid-cols-1
    sm:grid-cols-1
  `]);

export const CheckboxStyled = tw.input`accent-[#002145]`;

export const CompanyContainerStyled = tw.div`
  w-full
  py-[8px]
  flex
  items-center
  justify-between
  border-b
  border-solid
  border-gray-300
`;

export const ShowDetailButton = styled('button')(({ field }) => [tw`
  px-[8px]
  py-[4px]
  rounded-[8px]
  text-white
  text-caption1
  font-bold
  sm:text-caption2
  md:text-caption2
  hover:bg-indigo-900
`, (() => {
  switch (field) {
    case 'ai':
      return tw`bg-[#0B3740] hover:bg-[#a3ccb9]`;
    case 'bio':
      return tw`bg-[#00437c]`;
    case 'contents':
      return tw`bg-[#331544]`;
    default:
      return tw`bg-[#002145]`;
  }
})]);

export const SubmitButton = styled('button')(({ field }) => [tw`
  w-full
  py-[8px]
  text-center
  rounded-[8px]
  bg-[#002145]
  text-white
  font-bold
  text-body1
  sm:text-caption1
  md:text-body1
  hover:bg-indigo-900
`, (() => {
  switch (field) {
    case 'ai':
      return tw`bg-[#0B3740] hover:bg-[#a3ccb9]`;
    case 'bio':
      return tw`bg-[#00437c]`;
    case 'contents':
      return tw`bg-[#331544]`;
    default:
      return tw`bg-[#002145]`;
  }
})]);

export const AgreeContainerStyled = styled('div')([
  tw`
    w-full
    flex
    items-center
    justify-between
  `,
]);

export const DimmedStyled = tw.div`
  fixed
  top-0
  left-0
  w-screen
  h-screen
  flex
  items-center
  justify-center
  bg-[#00000080]
`;
