import React, { useCallback } from 'react';
import { InnerContainer, TitleText } from '../commonStyled';
import { OverviewStyled, ScheduleGraphStyled } from './OverviewStyled';
import Quantum from './Quantum';
import FinTech from './FinTech';

export default function Overview({ field, language }) {
  const renderOverview = useCallback(() => {
    switch (field){
      case 'quantum':
        return <Quantum />
      default:
        return <FinTech />
    }
  },[field])

  return <OverviewStyled>
    <InnerContainer>
      <TitleText className={`!text-white`}>OVERVIEW</TitleText>
      <ScheduleGraphStyled>
        {renderOverview()}
      </ScheduleGraphStyled>
    </InnerContainer>
  </OverviewStyled>;
}
