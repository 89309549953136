import React, { useCallback } from 'react';
import Quantum from './Quantum';
import FinTech from './FInTech';


export default function Invitation({ field, language }) {
  const renderInvitation = useCallback((type) => {
    switch (type) {
      case 'quantum':
        return <Quantum language={language} />;
      default:
        return <FinTech language={language} />;
    }
  }, [language]);

  return renderInvitation(field);
}
