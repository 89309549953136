import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { FintechScreen, QuantumScreen } from './screens';


function App() {
  return (<BrowserRouter>
    <Routes>
      <Route path={'/'} element={<FintechScreen />}/>
      <Route path={'/fintech'} element={<FintechScreen />}/>
      <Route path={'/quantum'} element={<QuantumScreen />}/>
    </Routes>
  </BrowserRouter>);
}

export default App;
